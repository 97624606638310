<template>
  <svg
    width="156"
    height="156"
    viewBox="0 0 156 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="156" height="156" fill="white" />
    <path
      d="M60.566 22.3833C29.8779 30.8793 11.8877 62.6443 20.3837 93.3324C28.8797 124.02 60.6446 142.011 91.3327 133.515C122.021 125.019 140.011 93.2537 131.515 62.5656C123.019 31.8776 91.2541 13.8873 60.566 22.3833Z"
      fill="#F2F8FF"
      stroke="#D6DCE8"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M96.1765 39.1478L97.2707 38.5955C97.3286 38.5794 97.4284 38.4894 97.3964 38.3736L97.3073 37.1511C97.3172 36.9613 97.4009 36.8134 97.5747 36.7653C97.7484 36.7172 97.9542 36.785 98.0023 36.9587L98.5547 38.0529C98.5707 38.1108 98.6607 38.2106 98.7766 38.1786L99.999 38.0896C100.189 38.0994 100.337 38.1831 100.385 38.3569C100.433 38.5306 100.365 38.7365 100.191 38.7846L99.0972 39.3369C99.0393 39.353 98.9395 39.4429 98.9716 39.5588L99.0606 40.7813C99.0508 40.971 98.967 41.1189 98.7933 41.167C98.6195 41.2152 98.4137 41.1474 98.3656 40.9737L97.8133 39.8795C97.7972 39.8215 97.7072 39.7217 97.5914 39.7538L96.3689 39.8428C96.1791 39.833 96.0312 39.7493 95.9831 39.5755C95.9511 39.4597 96.0188 39.2538 96.1765 39.1478Z"
      fill="#D6DCE8"
    />
    <path
      d="M43.6416 45.4399L44.6847 44.9134C44.7399 44.8981 44.8351 44.8123 44.8045 44.7019L44.7196 43.5364C44.729 43.3555 44.8088 43.2145 44.9745 43.1686C45.1401 43.1228 45.3363 43.1874 45.3822 43.353L45.9088 44.3961C45.9241 44.4514 46.0099 44.5465 46.1203 44.5159L47.2857 44.4311C47.4666 44.4404 47.6076 44.5203 47.6535 44.6859C47.6994 44.8516 47.6348 45.0478 47.4691 45.0936L46.426 45.6202C46.3708 45.6355 46.2756 45.7213 46.3062 45.8317L46.3911 46.9971C46.3817 47.1781 46.3019 47.3191 46.1362 47.3649C45.9706 47.4108 45.7744 47.3462 45.7285 47.1806L45.2019 46.1374C45.1866 46.0822 45.1009 45.9871 44.9904 46.0176L43.825 46.1025C43.6441 46.0932 43.5031 46.0133 43.4572 45.8477C43.4114 45.682 43.4759 45.4858 43.6416 45.4399Z"
      fill="#D6DCE8"
    />
    <path
      d="M81.0102 56.8625L81.8528 56.4372C81.8973 56.4249 81.9742 56.3556 81.9495 56.2664L81.881 55.3251C81.8885 55.179 81.953 55.0651 82.0868 55.028C82.2206 54.991 82.3791 55.0431 82.4161 55.1769L82.8414 56.0195C82.8538 56.064 82.923 56.1409 83.0122 56.1162L83.9535 56.0477C84.0996 56.0552 84.2135 56.1197 84.2506 56.2535C84.2876 56.3873 84.2355 56.5458 84.1017 56.5828L83.2592 57.0081C83.2146 57.0205 83.1377 57.0897 83.1624 57.1789L83.2309 58.1202C83.2234 58.2664 83.1589 58.3802 83.0251 58.4173C82.8913 58.4543 82.7328 58.4022 82.6958 58.2684L82.2705 57.4259C82.2582 57.3813 82.1889 57.3044 82.0997 57.3291L81.1584 57.3977C81.0123 57.3901 80.8984 57.3256 80.8613 57.1918C80.792 57.115 80.8888 56.9441 81.0102 56.8625Z"
      fill="#D6DCE8"
    />
    <path
      d="M47.8177 116.199L49.3304 115.436C49.4104 115.414 49.5484 115.289 49.504 115.129L49.381 113.439C49.3946 113.177 49.5104 112.972 49.7505 112.906C49.9907 112.839 50.2753 112.933 50.3418 113.173L51.1053 114.686C51.1275 114.766 51.2519 114.904 51.412 114.86L53.1019 114.736C53.3643 114.75 53.5688 114.866 53.6352 115.106C53.7017 115.346 53.6081 115.631 53.3679 115.697L51.8553 116.461C51.7753 116.483 51.6373 116.607 51.6816 116.767L51.8047 118.457C51.7911 118.72 51.6753 118.924 51.4351 118.991C51.1949 119.057 50.9104 118.964 50.8439 118.723L50.0804 117.211C50.0582 117.131 49.9338 116.993 49.7737 117.037L48.0837 117.16C47.8214 117.147 47.6169 117.031 47.5504 116.791C47.4839 116.55 47.5997 116.346 47.8177 116.199Z"
      fill="#D6DCE8"
    />
    <path
      d="M124.561 76.1737L125.353 74.985C125.403 74.9355 125.453 74.7869 125.353 74.6878L124.561 73.4991C124.462 73.301 124.462 73.1029 124.611 72.9543C124.759 72.8057 125.007 72.7562 125.155 72.9048L126.344 73.6973C126.394 73.7468 126.542 73.7963 126.641 73.6973L127.83 72.9048C128.028 72.8057 128.226 72.8057 128.375 72.9543C128.523 73.1029 128.573 73.3506 128.424 73.4991L127.632 74.6878C127.582 74.7374 127.533 74.886 127.632 74.985L128.424 76.1737C128.523 76.3718 128.523 76.5699 128.375 76.7185C128.226 76.8671 127.978 76.9167 127.83 76.7681L126.641 75.9756C126.592 75.9261 126.443 75.8765 126.344 75.9756L125.155 76.7681C124.957 76.8671 124.759 76.8671 124.611 76.7185C124.462 76.57 124.412 76.3223 124.561 76.1737Z"
      fill="#D6DCE8"
    />
    <path
      d="M47.958 67.6945L48.9721 65.6606C49.0404 65.5725 49.089 65.3282 48.9129 65.1915L47.4255 63.4732C47.2297 63.1802 47.1903 62.8675 47.3952 62.6034C47.6001 62.3393 47.9811 62.2118 48.2452 62.4168L50.2792 63.4309C50.3672 63.4992 50.6116 63.5478 50.7482 63.3717L52.4666 61.8843C52.7596 61.6885 53.0722 61.649 53.3363 61.854C53.6004 62.0589 53.7279 62.4399 53.523 62.704L52.5089 64.7379C52.4406 64.826 52.392 65.0704 52.5681 65.207L54.0555 66.9254C54.2513 67.2183 54.2907 67.531 54.0858 67.7951C53.8808 68.0592 53.4998 68.1867 53.2357 67.9818L51.2018 66.9677C51.1138 66.8994 50.8694 66.8508 50.7328 67.0268L49.0144 68.5143C48.7214 68.71 48.4087 68.7495 48.1446 68.5446C47.8805 68.3396 47.7531 67.9586 47.958 67.6945Z"
      fill="#D6DCE8"
    />
    <path
      d="M68.1578 127.774L69.5786 127.057C69.6538 127.036 69.7833 126.919 69.7417 126.768L69.6261 125.181C69.6389 124.935 69.7476 124.743 69.9732 124.68C70.1988 124.618 70.4661 124.706 70.5285 124.931L71.2457 126.352C71.2665 126.427 71.3834 126.557 71.5338 126.515L73.121 126.4C73.3675 126.412 73.5595 126.521 73.622 126.747C73.6844 126.972 73.5965 127.24 73.3709 127.302L71.9501 128.019C71.8749 128.04 71.7454 128.157 71.787 128.307L71.9026 129.895C71.8898 130.141 71.7811 130.333 71.5555 130.396C71.3299 130.458 71.0626 130.37 71.0002 130.144L70.283 128.724C70.2622 128.648 70.1453 128.519 69.9949 128.561L68.4077 128.676C68.1612 128.663 67.9692 128.555 67.9067 128.329C67.8443 128.103 67.9322 127.836 68.1578 127.774Z"
      fill="#D6DCE8"
    />
    <path
      d="M40.8469 91.4298L41.8352 89.6773C41.8996 89.6025 41.9535 89.3886 41.804 89.2599L40.5664 87.6736C40.4065 87.4057 40.3857 87.1275 40.5788 86.9032C40.7719 86.679 41.1145 86.5834 41.3388 86.7765L43.0913 87.7648C43.1661 87.8291 43.38 87.8831 43.5087 87.7336L45.095 86.496C45.3628 86.3361 45.6411 86.3153 45.8654 86.5084C46.0896 86.7015 46.1852 87.0441 45.9921 87.2683L45.0038 89.0209C44.9394 89.0957 44.8854 89.3095 45.035 89.4383L46.2726 91.0246C46.4325 91.2924 46.4533 91.5707 46.2602 91.7949C46.0671 92.0192 45.7245 92.1147 45.5002 91.9216L43.7477 90.9334C43.6729 90.869 43.459 90.815 43.3303 90.9645L41.744 92.2022C41.4761 92.3621 41.1979 92.3829 40.9736 92.1898C40.7494 91.9967 40.6538 91.6541 40.8469 91.4298Z"
      fill="#D6DCE8"
    />
    <path
      d="M65.6362 36.7606L67.4911 35.8647C67.6687 35.8155 67.7083 35.6134 67.6837 35.5246L67.4981 33.4731C67.5131 33.1821 67.7057 32.8421 67.9721 32.7683C68.2384 32.6946 68.5539 32.7984 68.741 33.129L69.6369 34.9839C69.6861 35.1614 69.8882 35.201 69.977 35.1765L72.0285 34.9908C72.3195 35.0059 72.6595 35.1985 72.7333 35.4648C72.807 35.7312 72.7032 36.0467 72.3726 36.2338L70.5178 37.1297C70.3402 37.1788 70.3006 37.381 70.3251 37.4698L70.5108 39.5213C70.4957 39.8122 70.3031 40.1523 70.0368 40.2261C69.7704 40.2998 69.4549 40.196 69.2678 39.8654L68.3719 38.0105C68.3228 37.833 68.1206 37.7933 68.0318 37.8179L65.9803 38.0035C65.6894 37.9885 65.3493 37.7959 65.2756 37.5295C65.1772 37.1744 65.2811 36.8589 65.6362 36.7606Z"
      fill="#D6DCE8"
    />
    <path
      d="M108.972 56.7554L109.904 56.3054C109.993 56.2807 110.013 56.1792 110.001 56.1346L109.907 55.1041C109.915 54.9579 110.012 54.7871 110.146 54.7501C110.279 54.713 110.438 54.7652 110.532 54.9312L110.982 55.8629C111.007 55.9521 111.108 55.972 111.153 55.9597L112.183 55.8665C112.329 55.874 112.5 55.9708 112.537 56.1045C112.574 56.2383 112.522 56.3968 112.356 56.4908L111.424 56.9408C111.335 56.9655 111.315 57.067 111.328 57.1116L111.421 58.1421C111.413 58.2882 111.316 58.459 111.183 58.4961C111.049 58.5331 110.89 58.481 110.796 58.3149L110.346 57.3832C110.322 57.294 110.22 57.2741 110.176 57.2865L109.145 57.3797C108.999 57.3722 108.828 57.2754 108.791 57.1416C108.754 57.0078 108.851 56.837 108.972 56.7554Z"
      fill="#D6DCE8"
    />
    <path
      d="M100.694 114.626L102.793 113.612C102.994 113.557 103.039 113.328 103.011 113.227L102.801 110.905C102.818 110.576 103.036 110.191 103.338 110.107C103.639 110.024 103.996 110.141 104.208 110.516L105.222 112.615C105.278 112.816 105.507 112.861 105.607 112.833L107.929 112.623C108.259 112.64 108.644 112.858 108.727 113.16C108.81 113.461 108.693 113.818 108.319 114.03L106.219 115.044C106.018 115.1 105.973 115.329 106.001 115.429L106.211 117.751C106.194 118.081 105.976 118.466 105.675 118.549C105.373 118.632 105.016 118.515 104.804 118.141L103.79 116.041C103.735 115.84 103.506 115.795 103.405 115.823L101.083 116.033C100.754 116.016 100.369 115.798 100.285 115.497C100.202 115.195 100.319 114.838 100.694 114.626Z"
      fill="#D6DCE8"
    />
    <path
      d="M110.532 93.1344L112.041 92.4056C112.186 92.3656 112.218 92.2012 112.198 92.129L112.047 90.4601C112.059 90.2234 112.216 89.9468 112.433 89.8868C112.649 89.8268 112.906 89.9112 113.058 90.1801L113.787 91.6891C113.827 91.8335 113.991 91.8658 114.063 91.8458L115.732 91.6948C115.969 91.707 116.246 91.8637 116.306 92.0804C116.366 92.297 116.281 92.5537 116.012 92.7059L114.503 93.4347C114.359 93.4747 114.327 93.6391 114.347 93.7114L114.498 95.3803C114.485 95.6169 114.329 95.8936 114.112 95.9536C113.895 96.0136 113.639 95.9291 113.487 95.6602L112.758 94.1512C112.718 94.0068 112.553 93.9746 112.481 93.9946L110.812 94.1456C110.576 94.1333 110.299 93.9766 110.239 93.76C110.179 93.5433 110.336 93.2666 110.532 93.1344Z"
      fill="#D6DCE8"
    />
    <path
      d="M26.5983 71.0333L28.8359 70.8308C29.0296 70.7772 29.18 70.9441 29.2069 71.0409L30.184 73.064C30.3613 73.3277 30.7322 73.5378 31.0227 73.4574C31.3132 73.377 31.5501 73.1029 31.5397 72.6887L31.3373 70.4511C31.2837 70.2574 31.4505 70.107 31.5473 70.0801L33.5705 69.103C33.8342 68.9257 34.0443 68.5548 33.9639 68.2643C33.8834 67.9738 33.6093 67.7369 33.1952 67.7473L30.9575 67.9498C30.7639 68.0034 30.6134 67.8365 30.5866 67.7397L29.6095 65.7165C29.4322 65.4528 29.0613 65.2427 28.7707 65.3231C28.4802 65.4036 28.2434 65.6777 28.2538 66.0918L28.4562 68.3295C28.5098 68.5231 28.343 68.6736 28.2461 68.7004L26.223 69.6775C25.9593 69.8548 25.7492 70.2258 25.8296 70.5163C25.9369 70.9036 26.3078 71.1137 26.5983 71.0333Z"
      fill="#D6DCE8"
    />
    <path
      d="M14.6363 115.566L17.8001 115.28C18.0739 115.204 18.2867 115.44 18.3246 115.577L19.7062 118.438C19.9568 118.811 20.4813 119.108 20.892 118.994C21.3028 118.88 21.6377 118.493 21.623 117.907L21.3368 114.743C21.261 114.469 21.4969 114.257 21.6338 114.219L24.4944 112.837C24.8672 112.587 25.1643 112.062 25.0505 111.651C24.9368 111.241 24.5493 110.906 23.9637 110.92L20.7999 111.207C20.526 111.282 20.3133 111.046 20.2754 110.91L18.8938 108.049C18.6432 107.676 18.1187 107.379 17.7079 107.493C17.2972 107.606 16.9622 107.994 16.977 108.58L17.2632 111.743C17.339 112.017 17.1031 112.23 16.9662 112.268L14.1056 113.65C13.7327 113.9 13.4357 114.425 13.5494 114.835C13.7011 115.383 14.2255 115.68 14.6363 115.566Z"
      fill="#F2F8FF"
    />
    <path
      d="M19.8898 35.2522L22.8069 34.9883C23.0594 34.9184 23.2556 35.1359 23.2905 35.2622L24.5644 37.8997C24.7955 38.2435 25.2791 38.5173 25.6578 38.4125C26.0365 38.3076 26.3453 37.9503 26.3318 37.4104L26.0679 34.4932C25.998 34.2408 26.2155 34.0446 26.3417 34.0097L28.9793 32.7358C29.323 32.5047 29.5969 32.0211 29.4921 31.6424C29.3872 31.2637 29.0299 30.9548 28.49 30.9684L25.5728 31.2323C25.3203 31.3022 25.1242 31.0847 25.0893 30.9585L23.8154 28.3209C23.5843 27.9772 23.1007 27.7033 22.722 27.8081C22.3432 27.913 22.0344 28.2703 22.048 28.8102L22.3119 31.7274C22.3818 31.9799 22.1643 32.176 22.038 32.2109L19.4005 33.4848C19.0567 33.7159 18.7829 34.1995 18.8877 34.5782C19.0275 35.0832 19.5111 35.3571 19.8898 35.2522Z"
      fill="#F2F8FF"
    />
    <path
      d="M135.453 89.8904L139.429 91.3075C139.802 91.3686 139.927 91.7717 139.897 91.958L139.97 96.1786C140.065 96.768 140.532 97.4186 141.091 97.5102C141.65 97.6018 142.27 97.3208 142.578 96.6062L143.995 92.6299C144.056 92.2573 144.459 92.1321 144.646 92.1627L148.866 92.0895C149.456 91.9948 150.106 91.5275 150.198 90.9687C150.289 90.4098 150.009 89.7898 149.294 89.4813L145.318 88.0642C144.945 88.0031 144.82 87.6 144.85 87.4137L144.777 83.1931C144.682 82.6037 144.215 81.9532 143.656 81.8615C143.097 81.7699 142.477 82.0509 142.169 82.7655L140.752 86.7418C140.691 87.1144 140.288 87.2396 140.101 87.209L135.881 87.2823C135.291 87.3769 134.641 87.8442 134.549 88.4031C134.427 89.1483 134.894 89.7988 135.453 89.8904Z"
      fill="#F2F8FF"
    />
    <path
      d="M52.0583 49.7536C51.6889 49.8559 51.4723 50.2383 51.5746 50.6077C51.6769 50.9771 52.0593 51.1937 52.4287 51.0914C52.7982 50.9892 53.0147 50.6068 52.9125 50.2373C52.8102 49.8679 52.4278 49.6513 52.0583 49.7536Z"
      fill="#D6DCE8"
    />
    <path
      d="M75.949 67.1943C75.5795 67.2965 75.363 67.6789 75.4652 68.0484C75.5675 68.4178 75.9499 68.6344 76.3194 68.5321C76.6888 68.4298 76.9054 68.0474 76.8031 67.678C76.7008 67.3086 76.3184 67.092 75.949 67.1943Z"
      fill="#D6DCE8"
    />
    <path
      d="M91.4676 39.0585C91.272 39.3881 91.3805 39.8139 91.7102 40.0096C92.0398 40.2053 92.4656 40.0967 92.6613 39.7671C92.857 39.4375 92.7484 39.0117 92.4188 38.816C92.0892 38.6203 91.6633 38.7289 91.4676 39.0585Z"
      fill="#D6DCE8"
    />
    <path
      d="M69.0721 25.7561C68.8764 26.0857 68.985 26.5116 69.3146 26.7073C69.6443 26.9029 70.0701 26.7944 70.2658 26.4648C70.4615 26.1351 70.3529 25.7093 70.0233 25.5136C69.6937 25.3179 69.2678 25.4265 69.0721 25.7561Z"
      fill="#D6DCE8"
    />
    <path
      d="M29.121 56.313C28.9253 56.6426 29.0339 57.0685 29.3635 57.2641C29.6931 57.4598 30.1189 57.3513 30.3146 57.0216C30.5103 56.692 30.4017 56.2662 30.0721 56.0705C29.7425 55.8748 29.3166 55.9834 29.121 56.313Z"
      fill="#D6DCE8"
    />
    <path
      d="M32.0292 83.6073C31.8335 83.9369 31.9421 84.3628 32.2717 84.5585C32.6013 84.7541 33.0271 84.6456 33.2228 84.3159C33.4185 83.9863 33.3099 83.5605 32.9803 83.3648C32.6507 83.1691 32.2249 83.2777 32.0292 83.6073Z"
      fill="#D6DCE8"
    />
    <path
      d="M39.6005 110.887C39.4048 111.216 39.5133 111.642 39.843 111.838C40.1726 112.034 40.5984 111.925 40.7941 111.595C40.9898 111.266 40.8812 110.84 40.5516 110.644C40.222 110.449 39.7961 110.557 39.6005 110.887Z"
      fill="#D6DCE8"
    />
    <path
      d="M57.91 124.827C57.7143 125.157 57.8229 125.583 58.1525 125.779C58.4822 125.974 58.908 125.866 59.1037 125.536C59.2994 125.206 59.1908 124.781 58.8612 124.585C58.5316 124.389 58.1057 124.498 57.91 124.827Z"
      fill="#D6DCE8"
    />
    <path
      d="M91.4051 125.051C91.2095 125.381 91.318 125.807 91.6477 126.002C91.9773 126.198 92.4031 126.089 92.5988 125.76C92.7945 125.43 92.6859 125.004 92.3563 124.809C92.0267 124.613 91.6008 124.721 91.4051 125.051Z"
      fill="#D6DCE8"
    />
    <path
      d="M114.71 104.258C114.514 104.587 114.623 105.013 114.952 105.209C115.282 105.405 115.708 105.296 115.903 104.966C116.099 104.637 115.991 104.211 115.661 104.015C115.331 103.82 114.906 103.928 114.71 104.258Z"
      fill="#D6DCE8"
    />
    <path
      d="M127.13 81.7053C126.934 82.035 127.043 82.4608 127.372 82.6565C127.702 82.8522 128.128 82.7436 128.323 82.414C128.519 82.0843 128.411 81.6585 128.081 81.4628C127.751 81.2671 127.325 81.3757 127.13 81.7053Z"
      fill="#D6DCE8"
    />
    <path
      d="M113.105 47.0772C112.91 47.4068 113.018 47.8326 113.348 48.0283C113.677 48.224 114.103 48.1154 114.299 47.7858C114.495 47.4562 114.386 47.0303 114.056 46.8346C113.727 46.639 113.301 46.7475 113.105 47.0772Z"
      fill="#D6DCE8"
    />
    <path
      d="M150.775 67.3292L136.976 49.0019L108.88 70.3824L122.679 88.7097L150.775 67.3292Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M129.982 83.4619L115.81 64.7388"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M136.913 78.2158L122.74 59.4927"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M143.843 72.9697L129.671 54.2466"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M141.333 55.2513L113.61 76.236"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M146.066 61.5024L118.343 82.4871"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M110.991 85.3657L117.05 80.7581L114.895 77.8973L108.836 82.505L110.991 85.3657Z"
      fill="#D6DCE8"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M117.838 109.185C115.639 110.85 112.512 110.414 110.844 108.209L108.078 104.555C107.94 104.374 107.977 104.109 108.158 103.972L115.48 98.4294C115.661 98.2926 115.926 98.3295 116.063 98.5107L118.829 102.165C120.467 104.392 120.008 107.542 117.838 109.185Z"
      fill="#D6DCE8"
      stroke="#86A6CB"
      stroke-width="2.0875"
      stroke-miterlimit="10"
    />
    <path
      d="M68.7992 129.491L55 111.164L83.0961 89.7834L96.8953 108.111L68.7992 129.491Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M89.9644 113.753L75.792 95.0295"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M83.0337 118.999L68.8613 100.276"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M76.103 124.245L61.9307 105.522"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M59.7314 117.019L87.4543 96.0346"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M64.4639 123.27L92.1868 102.285"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M94.7705 93.1563L88.7119 97.7639L90.8672 100.625L96.9258 96.017L94.7705 93.1563Z"
      fill="#D6DCE8"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M100.522 71.6557L86.3896 82.3533L105.819 108.022L105.842 108.052C105.842 108.052 105.842 108.052 105.865 108.082C106.673 109.086 110.454 107.507 114.341 104.564C118.228 101.622 120.745 98.4344 120.028 97.3621C120.028 97.3621 120.028 97.3621 120.005 97.3319L119.982 97.3017L100.552 71.6328L100.522 71.6557Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M107.494 80.8662L112.888 87.9931L112.911 88.0233L112.934 88.0535C113.681 89.1031 111.134 92.3134 107.247 95.2558C103.36 98.1983 99.6089 99.7551 98.7714 98.7739L98.7486 98.7437L98.7257 98.7135L93.3311 91.5867C94.1384 92.5906 97.9196 91.011 101.807 88.0686C105.694 85.1262 108.218 81.8856 107.494 80.8662Z"
      fill="#D6DCE8"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M101.035 59.7866C104.701 64.6524 101.833 73.0492 94.6176 78.5362C87.4025 84.0232 78.5498 84.54 74.8839 79.6742C74.8617 79.6447 74.8395 79.6152 74.8172 79.5857C74.795 79.5562 74.7506 79.4973 74.7284 79.4678C73.5557 77.53 78.2735 71.7156 85.3668 66.3212C92.4601 60.9268 99.333 57.9729 100.849 59.6034C100.871 59.6329 100.915 59.6918 100.938 59.7213C100.968 59.6982 100.99 59.7277 101.035 59.7866Z"
      fill="#D6DCE8"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M100.81 59.622L100.832 59.6513C101.244 60.2611 101.02 61.3546 100.252 62.6763C98.7079 65.372 95.0404 69.1731 90.2796 72.7894C85.5187 76.4057 80.8472 78.939 77.8729 79.6753C76.3969 80.0581 75.2871 79.9781 74.8142 79.4143L74.7921 79.385C74.77 79.3556 74.7478 79.3263 74.7257 79.297C73.5577 77.3691 78.2569 71.5844 85.3224 66.2175C92.3878 60.8506 99.2337 57.9118 100.743 59.5339C100.766 59.5633 100.788 59.5926 100.81 59.622Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M100.243 62.6905L100.265 62.7198C98.7227 65.4111 95.0595 69.206 90.3041 72.8165C85.5488 76.4269 80.8826 78.956 77.9118 79.6911L77.8897 79.6618C77.8676 79.6325 77.8454 79.6032 77.8454 79.6032C76.8474 77.9651 80.8864 72.9635 86.9442 68.3642C93.0322 63.7419 98.8977 61.2238 100.199 62.6319C100.199 62.6319 100.221 62.6612 100.243 62.6905Z"
      fill="#D6DCE8"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M89.2749 68.5669C89.3795 68.7701 88.8735 69.3903 88.1309 69.9547C87.3574 70.5426 86.6257 70.8632 86.4676 70.6535L81.4102 62.7178L82.8644 61.6125L89.2298 68.507L89.2749 68.5669Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M82.744 62.8316C83.6915 62.1105 83.899 60.7815 83.2075 59.8631C82.516 58.9447 81.1874 58.7847 80.24 59.5057C79.2925 60.2268 79.085 61.5558 79.7764 62.4742C80.4679 63.3926 81.7965 63.5526 82.744 62.8316Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M40.6251 39.2774C40.3887 40.8532 39.3644 43.9261 37.5522 44.3988C37.4735 44.3988 37.3947 44.3988 37.3159 44.4776C35.5825 44.714 33.6127 43.5321 32.352 42.6654C31.7217 42.1927 31.0914 41.6412 30.5398 41.0896C30.3035 40.8532 29.9883 40.5381 29.7519 40.3017C27.9397 38.3319 26.5215 35.8894 25.6548 33.2893C25.4972 32.7378 25.3396 32.1862 25.4184 31.6347C25.4184 31.4771 25.4972 31.3983 25.4972 31.3195C25.6548 30.9256 25.97 30.5316 26.4427 30.4528C26.8367 30.374 27.1518 30.5316 27.5458 30.6104C27.9397 30.768 28.2549 30.9256 28.6489 31.1619C27.7822 30.1377 27.5458 28.4043 27.5458 27.1436C27.5458 27.0648 27.5458 26.9072 27.6246 26.9072C27.7034 26.8284 27.9397 26.9072 28.0185 26.986C29.2792 27.9315 29.2792 29.6649 29.8307 31.0831C29.8307 30.0589 29.9095 29.0346 29.9095 28.0103C29.9095 27.3012 29.9883 26.4345 30.5398 25.9617C30.6186 25.8829 30.6974 25.8829 30.7762 25.8829C30.9338 25.8829 31.0126 26.0405 31.0914 26.1193C32.0369 27.7739 31.4853 29.8225 31.6429 31.7135C31.7217 32.4226 31.8005 33.1317 31.9581 33.8408C32.0369 34.156 32.1945 34.8651 32.352 35.5742C32.5096 35.8894 32.6672 36.2046 32.9036 36.5197C32.9824 36.6773 33.1399 36.8349 33.2975 36.9137C33.5339 37.1501 33.8491 37.3864 34.1642 37.6228C34.7946 37.938 35.4249 38.1743 36.134 38.3319C37.3947 38.5683 38.6553 38.5683 39.916 38.6471C40.1524 38.6471 40.3887 38.7259 40.5463 38.8835C40.6251 39.041 40.6251 39.1198 40.6251 39.2774Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M57.1715 65.357C55.5169 65.357 53.7835 64.8055 52.5228 64.0176C50.0803 62.4418 48.5833 60.078 47.165 57.9507L49.9227 56.1385C51.1834 58.0295 52.444 60.078 54.2562 61.1811C55.6745 62.1266 57.9594 62.4418 59.2201 61.3387C60.7959 59.9204 60.4019 57.2415 59.7716 55.2718C58.9837 52.8292 57.4867 50.7019 55.6745 49.3624L57.6443 46.7623C60.0868 48.5745 61.9778 51.2534 62.9233 54.3263C64.2627 58.5022 63.79 61.8114 61.4262 63.86C60.2444 64.8843 58.6685 65.357 57.1715 65.357Z"
      fill="#86A6CB"
    />
    <path
      d="M60.5603 39.1987C60.6391 40.3806 60.2451 41.5625 59.7724 42.7443C57.9602 47.4718 56.0692 52.1992 53.8631 56.7691C53.3903 57.6358 52.9964 58.5813 52.5236 59.448C51.8933 60.7087 51.1054 62.1269 49.6871 62.4421C48.5053 62.6785 47.4022 62.1269 46.3779 61.5754C46.0628 61.4178 45.7476 61.1814 45.59 60.8663C45.4324 60.5511 45.5112 60.1571 45.59 59.842C46.5355 55.1145 48.3477 50.7022 50.1599 46.2111C51.0266 44.005 51.9721 41.72 52.6812 39.4351C53.2327 37.8593 53.6267 35.8107 55.6753 35.6531C57.5662 35.5743 60.4027 37.0714 60.5603 39.1987Z"
      fill="#86A6CB"
    />
    <path
      d="M38.5769 53.3022C38.5769 53.4598 38.5769 53.5386 38.5769 53.6961C38.4981 54.7204 38.2617 55.6659 37.7102 56.5326C37.0799 57.4781 36.1344 58.266 35.0313 58.5024C34.7949 58.5812 34.5585 58.5812 34.3222 58.5812C33.2979 58.66 32.2736 58.3448 31.3281 57.9509C30.1462 57.4781 28.9644 56.9266 27.6249 56.8478C26.9158 56.769 26.2067 56.8478 25.4976 57.0054C25.1036 57.0842 24.7884 57.2417 24.3945 57.3205C24.0005 57.4781 23.6066 57.6357 23.2126 57.7933C21.8732 58.4236 20.5337 59.2903 19.1943 59.8418C18.0912 60.3146 16.7518 60.551 15.8063 59.8418C15.3335 59.5267 15.0971 58.9751 14.8608 58.5024C13.5213 55.9811 12.3394 53.4598 11.1576 50.8597C11.0788 50.7809 11 50.6233 11 50.4657C11 50.0717 11.4727 49.7566 11.7879 49.599C11.8667 49.5202 11.9455 49.5202 12.0243 49.4414C12.891 49.0475 13.6789 48.7323 14.6244 48.5747C15.6487 48.4959 16.7518 48.6535 17.4609 49.3626C17.9336 49.8354 18.2488 50.3869 18.5639 51.0172C18.8791 51.7264 19.2731 52.4355 19.5882 53.1446C20.5337 52.3567 21.4792 51.5688 22.5035 50.8597C22.8187 50.6233 23.1338 50.4657 23.449 50.3081C24.0005 49.993 24.6309 49.6778 25.2612 49.5202C26.2855 49.1263 27.3885 48.9687 28.4916 48.8899C29.0432 48.8111 29.5947 48.7323 30.1462 48.8111C30.7766 48.8111 31.4069 48.9687 31.9584 49.0475C33.4555 49.3626 35.0313 49.6778 36.5283 49.993L34.8737 49.8354C36.2132 50.1505 37.789 50.6233 38.3405 51.8839C38.5769 52.2779 38.5769 52.8294 38.5769 53.3022Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.7464 49.5202C32.1949 51.6475 32.6676 53.9325 34.0071 55.7446C34.0859 55.8234 34.1647 55.981 34.3222 55.9022C34.401 55.9022 34.401 55.8234 34.4798 55.7446C34.7162 55.1931 34.795 54.5628 34.8738 54.0112C35.0314 52.6718 35.5041 50.5444 35.0314 49.205C34.4798 47.9443 32.9828 48.4959 32.7464 49.5202Z"
      fill="#86A6CB"
    />
    <path
      d="M42.5163 34.3921C41.886 35.2588 41.0981 35.9679 40.389 36.7558C36.213 41.2469 34.2433 47.4714 33.0614 53.5383C32.7462 55.1141 32.5886 56.8475 33.5341 58.0294C34.0069 58.5809 34.6372 58.9749 35.2675 59.2901C36.2918 59.8416 37.2373 60.3931 38.2616 60.9447C39.4435 61.575 40.7041 62.2841 42.0436 62.3629C43.6194 62.4417 45.1952 61.6538 46.3771 60.5507C47.559 59.4476 48.3469 58.0294 49.1348 56.6112C51.1046 52.9868 52.9167 49.2836 54.4926 45.5016C54.9653 44.3985 55.4381 43.2167 55.5956 42.0348C55.832 40.3014 55.4381 38.568 55.1229 36.9134C55.0441 36.5194 54.9653 36.0467 54.6502 35.7315C54.2562 35.3376 53.6259 35.3376 53.1531 35.4164C49.6863 35.5739 46.1407 35.8891 42.8315 35.1012C42.1224 34.9436 41.3345 34.7072 40.9405 34.0769"
      fill="white"
    />
    <path
      d="M42.5163 34.3921C41.886 35.2588 41.0981 35.9679 40.389 36.7558C36.213 41.2469 34.2433 47.4714 33.0614 53.5383C32.7462 55.1141 32.5886 56.8475 33.5341 58.0294C34.0069 58.5809 34.6372 58.9749 35.2675 59.2901C36.2918 59.8416 37.2373 60.3931 38.2616 60.9447C39.4435 61.575 40.7041 62.2841 42.0436 62.3629C43.6194 62.4417 45.1952 61.6538 46.3771 60.5507C47.559 59.4476 48.3469 58.0294 49.1348 56.6112C51.1046 52.9868 52.9168 49.2836 54.4926 45.5016C54.9653 44.3985 55.4381 43.2167 55.5956 42.0348C55.832 40.3014 55.4381 38.568 55.1229 36.9134C55.0441 36.5194 54.9653 36.0467 54.6502 35.7315C54.2562 35.3376 53.6259 35.3376 53.1531 35.4164C49.6863 35.5739 46.1407 35.8891 42.8315 35.1012C42.1224 34.9436 41.3345 34.7072 40.9405 34.0769"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.498 57.084C25.104 57.1628 24.7888 57.3203 24.3949 57.3991C24.3161 56.2961 24.1585 55.0354 23.8434 53.8535C23.5282 52.8292 23.1342 51.8838 22.5039 50.9383C22.8191 50.7019 23.1342 50.5443 23.4494 50.3867C24.0797 51.411 24.5525 52.4353 24.8676 53.5384C25.1828 54.7202 25.4192 55.9809 25.498 57.084Z"
      fill="#86A6CB"
    />
    <path
      d="M36.2129 55.5084C35.8978 56.2963 36.0554 55.272 35.9766 56.2175C35.9766 57.0842 36.2129 57.9509 36.6857 58.7388C37.6312 60.0783 39.2858 60.8662 40.8616 61.4177C42.2799 61.8905 43.7769 62.1269 45.1951 61.4177C46.9285 60.551 48.268 58.5025 48.1104 56.5327C47.9528 53.9326 44.88 52.5931 42.595 52.3568C39.7585 52.0416 38.3403 53.0659 36.1341 54.7205V55.5084H36.2129Z"
      fill="#86A6CB"
    />
    <path
      d="M58.1961 30.5315C58.1961 30.6103 58.1173 30.7679 58.1173 30.8467C57.7233 32.5801 57.2506 34.2347 57.093 36.0469C57.0142 36.8348 57.1718 38.253 56.3839 38.8046C55.7536 39.1985 54.6505 38.9621 53.9414 38.9621C51.7352 38.8046 49.6079 38.4106 47.3229 38.4106C47.1653 38.4106 47.0078 38.4106 46.7714 38.4106C45.9835 38.3318 45.2743 38.0166 44.644 37.6227C43.7773 37.2287 42.9894 36.756 42.1227 36.4408C41.4136 36.2045 40.6257 36.0469 40.2317 35.4165C39.759 34.7862 39.759 33.9983 39.6802 33.2892C39.4438 30.9254 39.5226 28.4041 40.1529 26.1192C40.5469 24.7009 41.3348 23.2827 42.5167 22.2584C45.1168 19.8947 49.2927 19.4219 52.5231 20.9978C54.8869 22.1796 56.9354 24.2282 57.8809 26.7495C58.2749 27.8526 58.4325 29.192 58.1961 30.5315Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M58.1952 30.5315C58.1952 30.6103 58.1164 30.7679 58.1164 30.8466C57.7225 32.5801 57.2497 34.2347 57.0921 36.0469C57.0134 36.8348 57.1709 38.253 56.383 38.8046C55.7527 39.1985 54.6496 38.9621 53.9405 38.9621C51.7343 38.8046 49.607 38.4106 47.322 38.4106C47.1645 38.4106 47.0069 38.4106 46.7705 38.4106C47.716 38.0166 48.7403 38.0166 49.7646 37.8591C52.995 37.3075 55.8315 35.0226 57.0921 32.0285C57.4861 31.083 57.7225 30.0587 57.8013 29.0345C57.8801 28.2465 57.8801 27.4586 57.7225 26.7495C58.274 27.8526 58.4316 29.192 58.1952 30.5315Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.1049 37.0715C50.2382 37.8594 49.2139 38.411 48.1108 38.411C46.2198 38.411 44.2501 38.017 42.5166 37.1503C41.9651 36.8351 41.4924 36.52 41.0196 36.2048C40.7832 35.9684 40.4681 35.7321 40.3105 35.4957C39.759 34.8654 39.4438 34.1562 39.2862 33.2895C38.8135 31.4773 39.1286 29.2712 39.759 27.5378C40.4681 25.7256 41.5712 25.0165 43.4621 24.9377C45.3531 24.7801 47.5593 25.4104 49.3715 26.2771C49.4503 26.3559 49.5291 26.3559 49.6078 26.4347C50.3958 26.8287 51.1049 27.3014 51.6564 27.8529C54.3353 30.2955 53.6262 34.7078 51.1049 37.0715Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M46.9281 57.8721C46.9281 57.9509 46.9281 57.9509 46.9281 58.0297C46.9281 58.4236 46.8493 58.7388 46.6917 59.054C46.1402 60.551 44.5644 61.4965 42.9886 61.9692C41.4127 62.442 39.7581 62.5208 38.2611 62.9935C37.0004 63.3875 35.7398 63.939 34.7943 64.8057C34.5579 65.0421 34.3215 65.1997 34.164 65.4361C34.164 65.5148 34.0852 65.5148 34.0852 65.5936C32.3518 67.721 31.879 70.7938 29.8304 72.6848C29.6729 72.8424 29.5153 72.9212 29.3577 73C29.2001 73 29.0425 72.9212 28.885 72.9212C25.9697 71.6605 23.2908 70.0847 20.6119 68.4301C20.2179 68.1937 19.7452 67.8786 19.5088 67.4846C19.3512 67.1695 19.3512 66.8543 19.5088 66.5391C19.9028 65.3573 21.6362 63.939 22.4241 63.6239C23.2908 63.3087 24.3151 63.3875 25.1818 63.7026C26.0485 64.0178 26.9152 64.5693 27.6243 65.1209C27.7819 64.0178 28.097 62.8359 28.5698 61.8117C28.7274 61.4965 28.885 61.1813 29.0425 60.8662C29.2001 60.551 29.4365 60.3146 29.5941 59.9995C30.0668 59.2903 30.6971 58.66 31.3275 58.1085C32.1942 57.3206 33.1397 56.6114 34.0852 55.9811C35.5034 55.0356 37.0004 54.1689 38.5763 53.775C38.8126 53.6962 39.049 53.6962 39.2854 53.6174C40.8612 53.3022 42.5946 53.5386 44.0916 54.1689C44.9583 54.4841 45.7462 55.0356 46.2978 55.7447C46.6917 56.2963 46.9281 57.0842 46.9281 57.8721Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.716 64.8055C34.4796 65.0419 34.2433 65.1995 34.0857 65.4359C34.0857 65.5146 34.0069 65.5146 34.0069 65.5934C33.0614 64.4116 31.9583 63.4661 30.6977 62.757C30.1461 62.4418 29.5158 62.2054 28.8855 61.969C28.8067 61.8902 28.6491 61.8902 28.5703 61.8115C28.7279 61.4963 28.8855 61.1811 29.0431 60.866C29.1219 60.866 29.2006 60.9448 29.2794 60.9448C29.9098 61.1811 30.6189 61.4175 31.2492 61.8115C32.5099 62.5206 33.7705 63.5449 34.716 64.8055Z"
      fill="#86A6CB"
    />
    <path
      d="M70.6446 44.2413C70.6446 44.3201 70.6446 44.4776 70.5658 44.5564C70.3294 45.4231 69.4627 45.8959 68.596 46.0535C68.3597 46.1323 68.0445 46.1323 67.8081 46.1323C67.1778 46.211 66.6263 46.211 65.9959 46.3686C65.3656 46.5262 64.7353 46.9202 64.1049 47.2353C63.4746 47.5505 62.7655 47.7869 62.1352 47.9444C61.7412 48.0232 61.3473 48.102 60.9533 48.1808C59.2199 48.4172 57.4865 48.2596 55.8319 47.7081C54.8076 47.3929 54.0197 46.5262 53.5469 45.4231C52.8378 43.6897 52.9954 41.4836 54.65 40.4593C56.7774 39.1198 58.1168 41.0108 59.8502 41.7987C60.1654 41.9563 60.4806 42.0351 60.7957 42.1139C61.1897 42.1927 61.5836 42.1927 61.9776 42.1927C62.8443 42.1139 63.711 41.8775 64.4989 41.4836C65.9171 40.7745 67.1778 39.6714 68.4385 38.6471C68.6748 38.4895 68.9112 38.2531 69.2264 38.3319C69.5415 38.4107 69.6991 38.8047 69.6991 39.1986V39.2774C69.6203 39.5926 69.4627 39.9078 69.3839 40.2229C69.2264 41.6412 70.8022 42.9018 70.6446 44.2413Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M70.0149 47.6293L64.1844 18.9494C64.1056 18.6342 64.342 18.3978 64.5784 18.319C64.8935 18.2403 65.1299 18.4766 65.2087 18.713L71.0392 47.393C71.118 47.7081 70.8816 47.9445 70.6453 48.0233C70.4089 48.1021 70.0937 47.8657 70.0149 47.6293Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M70.5659 44.5562C70.3296 45.4229 69.4629 45.8956 68.5962 46.0532C68.3598 46.132 68.0446 46.132 67.8083 46.132C67.8083 46.0532 67.7295 45.9744 67.7295 45.8956C67.5719 45.5805 67.6507 45.1865 67.9658 44.9501C67.7295 44.8713 67.4931 44.7925 67.4143 44.5562C67.2567 44.1622 67.4143 43.6895 67.8083 43.5319L68.0446 43.4531C67.9658 43.4531 67.887 43.4531 67.887 43.4531C67.5719 43.4531 67.3355 43.2955 67.1779 42.9803C67.0204 42.5864 67.1779 42.1924 67.4931 41.9561C67.3355 41.8773 67.0991 41.7197 67.0203 41.5621C66.8628 41.1682 67.0203 40.6954 67.4143 40.5378L69.9356 39.356C69.8568 39.6711 69.6992 39.9863 69.6205 40.3014C69.2265 41.7197 71.0387 43.0591 70.5659 44.5562Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M71.8269 43.7684C71.7481 43.532 71.5117 43.3744 71.2753 43.3744L71.5117 43.2956C71.9057 43.138 72.0632 42.6653 71.9057 42.2713C71.7481 41.9562 71.3541 41.7986 71.039 41.8774L71.1965 41.7986C71.5905 41.641 71.7481 41.1683 71.5905 40.7743C71.5117 40.5379 71.3541 40.4591 71.1178 40.3804C71.4329 40.144 71.5905 39.75 71.4329 39.3561C71.2753 38.9621 70.8026 38.8045 70.4086 38.9621L67.7297 40.2228C67.3358 40.3804 67.1782 40.8531 67.3358 41.2471C67.4146 41.4834 67.5721 41.5622 67.8085 41.641C67.4934 41.8774 67.3358 42.2713 67.4934 42.6653C67.6509 42.9805 68.0449 43.138 68.3601 43.0593L68.1237 43.138C67.7297 43.2956 67.5721 43.7684 67.7297 44.1623C67.8085 44.3987 68.0449 44.5563 68.2813 44.5563C67.9661 44.7927 67.8873 45.1866 68.0449 45.5018C68.2025 45.8957 68.6752 46.0533 69.0692 45.8957L71.4329 44.7927C71.8269 44.6351 71.9844 44.2411 71.8269 43.7684Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M48.1899 29.4287C47.8747 29.9014 47.2444 30.059 46.7717 29.8226C46.6141 29.7438 46.3777 29.665 46.2201 29.5862C44.014 28.7195 41.6502 29.2711 41.0199 30.9257C40.8623 31.3196 40.3108 31.2408 40.3108 30.8469C40.232 30.2954 40.3108 29.665 40.5472 29.1135C41.3351 26.9861 43.9352 26.0406 46.3777 26.9861C47.008 27.2225 47.5596 27.6165 48.0323 28.0104C48.4263 28.4044 48.4263 28.9559 48.1899 29.4287Z"
      fill="#86A6CB"
    />
    <path
      opacity="0.4"
      d="M40.6251 41.4835C42.2009 42.2714 45.4314 43.2956 47.4011 43.4532C47.7951 43.532 47.9527 43.8472 47.8739 44.2411C46.5344 47.3928 45.4314 50.5444 45.0374 51.7263C44.9586 52.0415 44.6435 52.199 44.4071 52.1203C42.9888 51.7263 39.0493 50.6232 37.4735 49.9929C37.1583 49.9141 37.0795 49.5989 37.1583 49.2838C38.4978 45.9745 39.4432 43.0593 39.8372 41.7986C39.9948 41.4835 40.3887 41.3259 40.6251 41.4835Z"
      fill="#86A6CB"
    />
    <path
      d="M62.2145 47.8654C61.8205 47.9442 61.4266 48.023 61.0326 48.1018C60.4023 47.1563 60.0083 45.8956 60.0871 44.635C60.1659 43.9258 60.4023 42.9016 60.8751 42.1136C61.269 42.1924 61.663 42.1924 62.0569 42.1924C61.5842 42.9016 61.1902 43.9258 61.1114 44.7138C61.0326 45.8956 61.5054 47.1563 62.2145 47.8654Z"
      fill="#86A6CB"
    />
    <path
      d="M34.2437 37.6228C32.8255 38.5683 31.5648 39.7501 30.4617 41.0896C30.2254 40.8532 29.9102 40.538 29.6738 40.3017C30.6981 39.041 31.9588 37.9379 33.2982 36.9924L34.2437 37.6228Z"
      fill="#86A6CB"
    />
    <path
      d="M85.4574 29.2708C78.0511 26.8283 74.9782 36.1257 67.4143 32.1861L64.7354 19.1068C72.9296 22.5736 76.0813 14.6945 82.7785 16.1915L85.4574 29.2708Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
