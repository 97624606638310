<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.667 7.33342H8.66699V3.33341C8.66699 3.1566 8.59675 2.98703 8.47173 2.86201C8.34671 2.73699 8.17714 2.66675 8.00033 2.66675C7.82351 2.66675 7.65395 2.73699 7.52892 2.86201C7.4039 2.98703 7.33366 3.1566 7.33366 3.33341V7.33342H3.33366C3.15685 7.33342 2.98728 7.40365 2.86225 7.52868C2.73723 7.6537 2.66699 7.82327 2.66699 8.00008C2.66699 8.17689 2.73723 8.34646 2.86225 8.47149C2.98728 8.59651 3.15685 8.66675 3.33366 8.66675H7.33366V12.6667C7.33366 12.8436 7.4039 13.0131 7.52892 13.1382C7.65395 13.2632 7.82351 13.3334 8.00033 13.3334C8.17714 13.3334 8.34671 13.2632 8.47173 13.1382C8.59675 13.0131 8.66699 12.8436 8.66699 12.6667V8.66675H12.667C12.8438 8.66675 13.0134 8.59651 13.1384 8.47149C13.2634 8.34646 13.3337 8.17689 13.3337 8.00008C13.3337 7.82327 13.2634 7.6537 13.1384 7.52868C13.0134 7.40365 12.8438 7.33342 12.667 7.33342Z"
      fill="#197DF2"
    />
  </svg>
</template>
