interface IOverrideOptions {
  [x: string]: Record<string, unknown> | undefined;
}
interface IProviderDataConfig {
  provider: string;
  code: string;
  providerData: IOverrideOptions;
  token?: string;
}

enum IProviderType {
  GOOGLE = "google",
  MICROSOFT = "microsoft",
}

enum IProviderName {
  MICROSOFT_GRAPTH = "microsoft-graph",
  GOOGLE_OAUTH2 = "google-oauth2",
}
interface IProviderSendData {
  code: string;
  provider: keyof typeof IProviderName | string;
  redirect_uri: string;
  token?: string;
}
interface ISocialProvider {
  id: number;
  provider: IProviderName;
}

type ISocialsList = ISocialProvider[];

export {
  ISocialProvider,
  ISocialsList,
  IProviderDataConfig,
  IProviderSendData,
  IOverrideOptions,
  IProviderName,
  IProviderType,
};
