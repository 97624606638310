<template>
  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="20" width="100" height="100" rx="50" fill="#EB4739" />
    <g filter="url(#filter0_d_13365_21471)">
      <rect
        x="40"
        y="20"
        width="60"
        height="60"
        rx="30"
        fill="white"
        shape-rendering="crispEdges"
      />
      <path
        d="M76 44L64 56"
        stroke="#BA2B1F"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64 44L76 56"
        stroke="#BA2B1F"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_13365_21471"
        x="0"
        y="0"
        width="140"
        height="140"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="20" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_13365_21471"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_13365_21471"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
