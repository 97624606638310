import http from "@/services/http";
import {
  IProviderDataConfig,
  IProviderSendData,
  IProviderName,
} from "@/core/types/social-auth";

const formatData = (payload: IProviderDataConfig): IProviderSendData => {
  const data = {
    code: payload.code,
    provider: "",
    redirect_uri: "",
    token: payload?.token,
  };
  if (!data.token) delete data.token;
  if (payload.provider === "microsoft") {
    data.provider = IProviderName.MICROSOFT_GRAPTH;
    const redirectUri = payload.providerData?.microsoft?.redirectUri ?? "";
    if (typeof redirectUri === "string") data.redirect_uri = redirectUri;
  }
  if (payload.provider === "google") {
    data.provider = IProviderName.GOOGLE_OAUTH2;
    const redirectUri = payload.providerData?.google?.redirectUri ?? "";
    if (typeof redirectUri === "string") data.redirect_uri = redirectUri;
  }
  return data;
};

const getTokenViaSocialConnection = async (
  payload: IProviderDataConfig
): Promise<void> => {
  try {
    const data = formatData(payload);
    await http.socialAuthConnecntion(data);
  } catch (err) {
    if (err) {
      throw err;
    }
  }
};

export { getTokenViaSocialConnection };
