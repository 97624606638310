<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.667 7.33325H3.33366C3.15685 7.33325 2.98728 7.40349 2.86225 7.52851C2.73723 7.65354 2.66699 7.82311 2.66699 7.99992C2.66699 8.17673 2.73723 8.3463 2.86225 8.47132C2.98728 8.59635 3.15685 8.66659 3.33366 8.66659H12.667C12.8438 8.66659 13.0134 8.59635 13.1384 8.47132C13.2634 8.3463 13.3337 8.17673 13.3337 7.99992C13.3337 7.82311 13.2634 7.65354 13.1384 7.52851C13.0134 7.40349 12.8438 7.33325 12.667 7.33325Z"
      fill="#197DF2"
    />
  </svg>
</template>
